import styled from "styled-components"

const ContactForm = styled.div`
  display: block;
  margin: 0 auto;
  form {
    max-width: 500px;
    margin: 2rem auto;
    border: 5px dotted #2b2b2b;
    padding: 1rem;
  }
  p {
    font-family: "Western Bang Bang";
    font-size: 30px;
    margin: 50px 0 10px 0;
    fon-weight: 500;
  }
  label {
    display: block;
  }
  label,
  textarea,
  input {
    display: block;
    width: 100%;
  }
  input {
    border: 5px solid #2b2b2b;
    color: white;
    background: none;
    font-size: 24px;
    padding: 5px;
  }

  textarea {
    border: 5px solid #2b2b2b;
    color: white;
    background: none;
    height: 250px;
    font-size: 24px;
  }
  .sendBtn {
    display: block;
    color: white;
    max-width: 250px;
    padding: 0.5em 1em;
    border: 5px solid #fff;
    background-color: #151515;
    margin: 36px auto 0px auto;
    font-family: "Western Bang Bang";
    font-size: 32px;
    text-decoration: none;
    text-align: center;
    box-shadow: 0px 0px #000;
    transition: all ease-in-out 0.5s;
    cursor: pointer;
    &:hover {
      color: #bdd230;
      border: 5px solid #bdd230;
    }
  }
`

export default ContactForm
