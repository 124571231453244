import React, { useState } from "react"
import styled from "styled-components"

const MenuContainer = styled.div`
  display: block;
  p {
    font-size: 16px;
  }
  h3,
  h4,
  h5 {
    font-size: 30px;
    font-weight: 500;
    font-family: "Western Bang Bang";
    ${"" /* border-bottom: 5px dotted #bdd230; */}
  }
  h4 {
    color: #888888;
    margin-bottom: 10px;
    border-bottom: 5px dotted #bdd230;
  }
  .category {
    margin-top: 50px;
    max-width: 350px;
    ${"" /* max-width: 500px; */}
  }
  ol {
    font-family: "Segoe UI";
  }
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  margin: 0 auto;
  border: 5px dotted white;
  flex-wrap: wrap;
  button {
    display: block;
    color: white;
    max-width: 250px;
    padding: 0.5em 1em;
    border: 5px solid #fff;
    background-color: #151515;
    margin: 15px auto;
    font-family: "Western Bang Bang";
    font-size: 32px;
    text-decoration: none;
    text-align: center;
    box-shadow: 0px 0px #000;
    transition: all ease-in-out 0.5s;
    cursor: pointer;
  }

  button:hover {
    color: #bdd230;
    border: 5px solid #bdd230;
  }
`
const MenuCatering = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`
const MenuRegular = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`

const Menu = () => {
  const [changeMenu, setChangeMenu] = useState(false)
  // const [changeMenu, setChangeMenu] = useState(false)
  const changeMenuHandler = () => setChangeMenu(!changeMenu)
  const renderMenu = () => {
    if (changeMenu) {
      return (
        <MenuCatering>
          <div className="category">
            <h3>
              WOOD FIRED <span>TACOS</span> (Soft Shell Corn tortillas/Gluten
              Free)
            </h3>
            <h4>BEEF</h4>
            <p>
              Slow braised wood fired beef brisket, homemade mojita sauce, salsa
              mexicana, lime
            </p>
            <h4>CHICKEN</h4>
            <p>
              Char-Grilled achiote chicken finished in wood fire, crispy
              lettuce, Jack gastrique, salsa mexicana, lime
            </p>
            <h4>FISH</h4>
            <p>
              Beer battered fresh snapper, smoked chipotle mayonnaise, mex-slaw,
              fresh mango, herbs
            </p>
            <h4>VEGIE</h4>
            <p>
              Wood fired ancho roasted vegies, mex slaw, homemade chipotle sauce
              and herbs
            </p>
            <h4>QUESADILLA</h4>
            <p>
              Wood fired braised lamb with herb fetta cheese, burnt onions and
              hot green sauce.
            </p>
          </div>

          <div className="category">
            <h3>
              STREET <span>SNACKS</span> (Gluten Free)
            </h3>
            <h4>Totopos con Guacamole (Gluten Free)</h4>
            <p>Corn chips with guacamole.</p>
            <h4>Char Grilled Corn (Gluten Free)</h4>
            <p>Homemade Chipotle mayonnaise and spices, cheese, lime.</p>
            <h4>Chicken Wings (Gluten Free)</h4>
            <p>
              Brined wings/winglets with burnt dark agave nectar, roasted.
              pumpkin seeds.
            </p>
            <h4>Cochinita Pibil Pork Sliders</h4>
            <p>
              Slow cooked pork shoulder, pickled onions, fresh Jalapeños,
              Valentina Sauce
            </p>
            <h4>Beef Brisket Chimichangas</h4>
            <p>
              Slow braised beef brisket wrapped into a crunch tortilla served
              with Cucumber/chilli vinegar and Chipotle sauce.
            </p>
            <h4>Vegie/Vegan Chimichangas</h4>
            <p>
              Rosted Vegies wrapped into a flour tortilla served with
              Cucumber/chilli vinegar and Chipotle sauce
            </p>
            <h4>Chicken Tostadas</h4>
            <p>
              Chargrill Achiote Chicken, crunch lettuce, Chipotle Mayo, pico de
              gallo, lime
            </p>
            <h4>Corn & Jalapeños Croquettes</h4>
            <p>...</p>
            <h4>CREATE YOUR OWN PACKAGE</h4>
            <ol>
              <li>
                Option($20) - 1 x Street Snack + 2 tacos(minimum 75 guests){" "}
              </li>
              <li>
                Option($25) 2 x Street Snacks + 2 tacos(minimum 70 guests)
              </li>
              <li>
                Option($30) 3 x Street Snacks + 2 tacos(minimum 50 guests)
              </li>
              <li>
                Option($40) 3 x Street Snacks + 2 tacos + Quesadilla(minimum 40
                guests){" "}
              </li>
            </ol>
            <p>GST is not included - Travel fee may be applied.</p>
          </div>
        </MenuCatering>
      )
    } else {
      return (
        <MenuRegular>
          <div className="category">
            <h3>
              WOOD FIRED <span>TACOS</span> (Soft Shell Corn tortillas/Gluten
              Free)
            </h3>
            <h4>FISH</h4>
            <p>
              Beer battered fresh snapper, smoked chipotle mayonnaise, mex-slaw,
              Paw paw, cucumber/chilli salsa, lime.
            </p>
            <h4>BEEF (Gluten Free)</h4>
            <p>
              Slow braised wood fired beef brisket, chipotle sauce, chimchurri,
              confit shallots, lime.
            </p>
            <h4>PORK PIBIL (Gluten Free)</h4>
            <p>
              Chochinita style slow roasted pork shoulder, sauce picante,
              pineapple chutney, herbs, lime
            </p>
            <h4>CHICKEN (Gluten Free)</h4>
            <p>
              Char-Grilled achiote chicken finished in wood fire, crispy
              lettuce, Jack gastrique, salsa mexicana, lime
            </p>

            <h4>VEGIE/VEGAN (Gluten Free)</h4>
            <p>
              Wood fired ancho roasted vegies, mex slaw, homemade chipotle
              sauce, herbs, lime
            </p>
          </div>
          <div className="category">
            <h3>
              OTHER DELICIOUS <span>FOOD</span>
            </h3>
            <h4>TOTOPOS (Gluten Free / Vegan)</h4>
            <p>Corn chips, chipotle sauce, guacamole, crema.</p>
            <h4>MEXICANA SALAD</h4>
            <p>
              (Gluten Free / Vegan available) Ask staff for details. Add Polo or
              Carne.
            </p>
            <h4>CALIFORNIA BURRITO (Vegie, Chicken, Fish)</h4>
            <p>
              Mixed green leave, mex slaw, thick fries, salsa picante, flamed
              cheese, salsa mexicana, lime.
            </p>
            <h4>PANCHO DRUMMETES (Gluten Free)</h4>
            <p>
              Salted brined chicken drummetes, dark agave nectar, roasted
              pumpkin seeds, lime.
            </p>
            <h4>NACHOS (Gluten Free)</h4>
            <p>
              Corn chips, flamed cheese, salsa mexicana, homemade sauce, lime.
              <br />
              <strong>
                Beef or Vegie/chipotle sauce, Chicken/chipotle mayo.
              </strong>
            </p>
            <h4>KIDS NACHOS</h4>
            <p>Beef, Chicken, Vegie.</p>
            <h4>SIDES</h4>
            <p>Guacamole | Crema | Jalapenos</p>
          </div>
        </MenuRegular>
      )
    }
  }
  return (
    <>
      <ButtonContainer>
        <button
          style={
            !changeMenu
              ? { color: "#bdd230", borderColor: "#bdd230" }
              : { color: "white", borderColor: "white" }
          }
          onClick={changeMenuHandler}
        >
          Standard Menu
        </button>
        <button
          style={
            changeMenu
              ? { color: "#bdd230", borderColor: "#bdd230" }
              : { color: "white", borderColor: "white" }
          }
          onClick={changeMenuHandler}
        >
          Catering Menu
        </button>
      </ButtonContainer>
      <MenuContainer>{renderMenu()}</MenuContainer>
    </>
  )
}

export default Menu
