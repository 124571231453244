import styled from "styled-components"

const Container = styled.div`
  position: relative;
  z-index: 2;
  margin: 100px auto 0px auto;
  width: 90%;
  max-width: 1020px;
  padding: 0 1.0875rem 1.45rem;
  h1,
  h2 {
    text-align: center;
    font-family: "Western Bang Bang";
    font-weight: 300;
    font-size: 5em;
  }
  h1 {
    margin-top: 100px;
  }
  h2 {
    margin-top: 150px;
  }
  p {
    font-family: Segoe UI;
  }
  span {
    color: #bdd230;
  }
  .goback {
    display: block;
    color: white;
    max-width: 250px;
    padding: 0.5em 1em;
    border: 5px solid #fff;
    background-color: #151515;
    margin: 15px auto;
    font-family: "Western Bang Bang";
    font-size: 32px;
    text-decoration: none;
    text-align: center;
    box-shadow: 0px 0px #000;
    transition: all ease-in-out 0.5s;

    &:hover {
      color: #bdd230;
      border: 5px solid #bdd230;
    }
  }
`
export default Container
