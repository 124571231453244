import styled from "styled-components"

const SocialLink = styled.a`
  color: white;
  font-size: 50px;
  padding: 0px 10px;
  transition: all 0.5s;
  &:hover {
    color: #bdd230;
    transform: scale(1.5);
  }
`

export default SocialLink
