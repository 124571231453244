import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import CustomLink from "../components/customLink"
import Container from "../components/container"
import Video from "../videos/zicatela-video.mp4"
import AboutContainer from "../components/aboutContainer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons"
import SocialLink from "../components/socialLink"
import InstagramGallery from "../components/instagramGallery"
import Menu from "../components/menu"
import ContactForm from "../components/contactForm"

const IndexPage = () => (
  <Layout>
    <SEO title="Mexican Food Truck" />

    <video id="home" muted preload="auto" loop autoPlay playsInline>
      <source src={Video} type="video/mp4" />
    </video>

    {/* <HomeHero /> */}
    <Container>
      <section id="about">
        <h1>
          About <span>Zicatela</span>
        </h1>
        <AboutContainer>
          <Image></Image>
          <div className="content">
            <p>
              Here at Zicatela, chef Mauricio produces his fresh take on modern
              and street style Mexican cuisine, after 10 year of experience
              cooking in the best Mexican restaurants in Sydney and Gold Coast.
            </p>
            <p>
              Quality fresh produce is sourced locally with renowned meat and
              seafood suppliers contributing to delivering an authentic product.
            </p>
            <p>
              Our menu is designed to encourage sharing and has been structured
              in a way to allow multiple courses, and our tortillas are
              traditionally pressed daily using corn masa flour coming from
              Mexico.
            </p>
            <p>
              Snacks, tapas and tacos all show traditional flavours and Mexican
              textures.
            </p>
          </div>
        </AboutContainer>
        <h2>
          Follow <span>Us</span>
        </h2>
        <div
          style={{
            padding: 10,
            margin: `0 auto`,
            maxWidth: `200px`,
            display: `flex`,
            justifyContent: `space-around`,
          }}
        >
          <SocialLink
            href="https://www.facebook.com/ZicatelaMexican/"
            target="_blank "
            rel="noopener noreferrer nofollow"
          >
            <FontAwesomeIcon icon={faFacebook} />
          </SocialLink>
          <SocialLink
            href="https://www.instagram.com/zicatelamexican/"
            target="_blank "
            rel="noopener noreferrer nofollow"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </SocialLink>
        </div>
      </section>
      <section>
        <h2>
          Our <span>Instagram</span>
        </h2>
        <InstagramGallery />
        <CustomLink
          href="https://www.instagram.com/zicatelamexican/"
          target="_blank"
          rel="noopener noreferrer"
        >
          View More
        </CustomLink>
      </section>

      <section id="menu">
        <h2>
          Our <span>Menus</span>
        </h2>
      </section>

      <Menu />
      <section id="contact">
        <h2>
          Contact <span>Us</span>
        </h2>
        <ContactForm>
          <form
            name="contact"
            method="post"
            action="/success/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
              <label>
                Don’t fill this out: <input name="bot-field" />
              </label>
            </p>
            <label>
              <p>Your Name (Required)</p>
              <input type="text" name="name" />
            </label>
            <label>
              <p>Email</p>
              <input type="email" name="_replyto" required />
            </label>
            <label>
              <p>Date of Event</p>
              <input type="date" />
            </label>
            <label>
              <p>Phone Number</p>
              <input
                type="tel"
                name="Phone"
                pattern="^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{1}( |-){0,1}[0-9]{3}$"
                placeholder="0000-000-000"
              />
            </label>
            <label>
              <p>Location</p>
              <input type="text" name="location" />
            </label>
            <label>
              <p>Number of Guests</p>
              <input type="number" name="guests" />
            </label>
            <label>
              <p>Message (Required)</p>
              <textarea name="message" required />
            </label>
            <button type="submit" className="goback">
              Submit
            </button>
          </form>
        </ContactForm>
      </section>
    </Container>
  </Layout>
)

export default IndexPage
