import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
const GalleryContainer = styled.div`
  display: grid;

  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-content: center;
  a {
    display: block;
    color: black;
    align-self: center;
    background-color: #bdd230;
    text-align: center;
  }
`

const Item = styled.div`
  position: relative;

  height: 300px;
  a {
    position: absolute;
    top: 0px;
    font-size: 16px;
    padding: 1em;
    opacity: 0;
    background: rgba(255, 255, 255, 0.9);
    height: 100%;
    width: 100%;
    color: black;
    padding-top: 75px;
    transition: all 0.5s ease;
    cursor: pointer;
  }
  a:hover {
    opacity: 1;
  }
`
const InstagramGallery = () => {
  const {
    allInstaNode: { edges },
  } = useStaticQuery(graphql`
    {
      allInstaNode(sort: { fields: timestamp, order: DESC }, limit: 6) {
        edges {
          node {
            id
            caption
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)
  const renderImages = () => {
    const images = edges.map(({ node }) => {
      const {
        id,
        caption,
        localFile: { childImageSharp },
      } = node
      // let newCaption = caption.sub
      return (
        <Item key={id}>
          <Img
            fluid={childImageSharp.fluid}
            style={{ width: "100%", height: "100%" }}
          />
          <a
            rel="noopener noreferrer"
            href={`https://www.instagram.com/p/${id}`}
            target="_blank"
          >
            {caption.substr(0, 150)}...Read More
          </a>
        </Item>
      )
    })
    return images
  }
  return <GalleryContainer>{renderImages()}</GalleryContainer>
}

export default InstagramGallery
