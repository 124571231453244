import styled from "styled-components"

const CustomLink = styled.a`
  display: block;
  color: white;
  max-width: 250px;
  padding: 0.5em 1em;
  border: 5px solid #fff;
  background-color: #151515;
  margin: 15px auto;
  font-family: "Western Bang Bang";
  font-size: 32px;
  text-decoration: none;
  text-align: center;
  box-shadow: 0px 0px #000;
  transition: all ease-in-out 0.5s;

  &:hover {
    color: #bdd230;
    border: 5px solid #bdd230;
  }
`

export default CustomLink
