import styled from "styled-components"

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${"" /* justify-content: center;
  align-items: center; */}
  margin: 0 auto;
  overflow: hidden;

  .mauricio {
    display: inline-block;
    position: relative;
    margin: 10px auto 30px auto;
    min-height: 280px;
    min-width: 280px;
    max-height: 300px;
    max-width: 300px;
    z-index: 1;
  }

  .mauricio::before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    background: none;
    position: absolute;
    top: 1em;
    left: 1em;

    border: 8px solid #bdd230;
  }

  .content {
    padding-left: 0px;
  }
  @media only screen and (min-width: 797px) {
    flex-direction: row;

    .content {
      padding-left: 40px;
    }
  }
`
export default AboutContainer
